import styled from "styled-components";
import Signin from "components/Signin";
import { Wrapper as Button } from "components/Button/styles";

export const Wrapper = styled.div`
  ${Button} {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const Message = styled.div`
  border-radius: ${(p) => p.theme.base.borderRadius};
  padding: 20px;
  line-height: 20px;
  border: 1px solid ${(p) => p.theme.colors.error};
  margin-bottom: 10px;

  span {
    color: ${(p) => p.theme.colors.error};
  }
`;

export const Link = styled.a`
  display: block;
  padding: 15px;
  text-align: center;
  color: ${(p) => p.theme.colors.tertiary};
  text-decoration: underline;
`;

export const SignInButton = styled(Signin)`
  text-align: center;
  border: 0 none;
  text-decoration: underline;
  font-size: 14px;
`;
