import React from "react";
import PropTypes from "prop-types";
// Components
import FormGroup from "components/Form/FormGroup";
// Styles
import { Label } from "../styles";
import { Control } from "./styles";

function Select({
  labelKey,
  valueKey,
  label,
  type,
  field,
  form,
  options,
  placeholder,
  ...props
}) {
  const currentValue = form.values[field.name];

  let render = [];

  if (options) {
    render = options.map(option => {
      const label = option[labelKey];
      const value = option[valueKey] || option[labelKey];

      // Verifica se está selecionado o valor
      const isSelected = currentValue === value;

      return (
        <option selected={isSelected} key={value} value={value}>
          {label}
        </option>
      );
    });
  }

  return (
    <FormGroup form={form} field={field}>
      {label ? <Label>{label}</Label> : null}
      <Control {...field} {...props}>
        {placeholder && <option>{placeholder}</option>}
        {render}
      </Control>
    </FormGroup>
  );
}

Select.defaultProps = {
  label: null,
  type: "text",
  field: null,
  form: null,
  labelKey: "label",
  valueKey: "value"
};

Select.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string
};

export default Select;
