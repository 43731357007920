import React, { useEffect } from "react";
import { navigate } from "gatsby";
import {
  Wrapper,
  Title,
  Text,
  Row,
  ColPassword,
  Btn,
  BoxTerms,
  Error,
} from "./styles";
import EmailExists from "./components/EmailExistis";
import { Formik, FastField } from "formik";
import {InputBoxed, Select} from "components/Form";
import { addMethod, string, object, ref, boolean } from "yup";
import { equalTo, PHONE_MASK, PhoneMask } from "helpers/form-helpers";
import { signup } from "services/auth";
import { getReferrer } from "helpers/referrer";
import { loadRecaptcha } from "helpers/recaptcha";

addMethod(string, "equalTo", equalTo);

const workOptions = [
  { label: 'Corretor de imóveis', value: 'Corretor de imóveis' },
  { label: 'Imobiliária', value: 'Imobiliária' },
  { label: 'Construtora', value: 'Construtora' },
  { label: 'Loteadora', value: 'Loteadora' },
  { label: 'Agência de marketing', value: 'Agência de marketing' },
  { label: 'Outro', value: 'Outro' },
];

const teamSizeOptions = [
  { label: '1 pessoa', value: '1 pessoa' },
  { label: '2 a 5 pessoas', value: '2 a 5 pessoas' },
  { label: '6 a 10 pessoas', value: '6 a 10 pessoas' },
  { label: '10 a 20 pessoas', value: '10 a 20 pessoas' },
  { label: 'Mais de 20 pessoas', value: 'Mais de 20 pessoas' },
];

const marketTimeOptions = [
  { label: 'Menos de 1 ano', value: 'Menos de 1 ano' },
  { label: '1 a 5 anos', value: '1 a 5 anos' },
  { label: '5 a 10 anos', value: '5 a 10 anos' },
  { label: 'Mais de 10 anos', value: 'Mais de 10 anos' },
  { label: 'Ainda não tenho CRECI', value: 'Ainda não tenho CRECI' },
];

function formatPhoneNumber(phoneNumber) {
  // Remove all non-numeric characters from the input number
  const numericOnly = phoneNumber.replace(/\D/g, "");

  // Ensure the numericOnly value has 11 digits
  if (numericOnly.length !== 11) {
    return "Invalid phone number"; // You can handle this case as you prefer
  }

  // Apply the regular expression to format the phone number
  const formattedNumber = numericOnly.replace(
    /^(\d{2})(\d)(\d{4})(\d{4})$/,
    "($1) $2 $3-$4"
  );
  return formattedNumber;
}

const Form = () => {
  const removeDigits = (number) => {
    return `55${number}`.replaceAll(/[^0-9]/g, "");
  };

  useEffect(() => {
    loadRecaptcha();
  }, []);

  return (
    <Wrapper>
      <header>
        <Title>Vamos começar</Title>
        <Text>
          Sem contrato de fidelidade e <br />
          sem surpresas desagradáveis
        </Text>
      </header>
      <Formik
        validationSchema={object({
          name: string().required("Campo obrigatório"),
          email: string()
            .email("Campo deve ser um e-mail")
            .required("Campo obrigatório"),
          cellphone: string().required("Campo obrigatório"),
          password: string()
            .min(6, "Pelo menos 6 dígitos")
            .required("Campo obrigatório"),
          password_confirm: string()
            .equalTo(ref("password"), "Senhas diferentes")
            .required("Campo obrigatório"),
          accept_terms: boolean().oneOf([true], "Você deve aceitar os termos."),
          work_option: string().required("Campo obrigatório"), // Adicionado
          team_size: string().required("Campo obrigatório"), // Adicionado
          market_time: string().required("Campo obrigatório"), // Adicionado
        })}
        initialValues={{
          name: "",
          email: "",
          cellphone: "",
          password: "",
          password_confirm: "",
          accept_terms: false,
          register_origin: "site-projeto",
          resale_id: "3390c2f2-cc7a-4f15-8213-05fed687eee7",
          work_option: "", // Adicionado
          team_size: "", // Adicionado
          market_time: "", // Adicionado
        }}
        onSubmit={async (values, actions) => {
          try {
            if (values.cellphone.length >= 11) {
              values.cellphone = formatPhoneNumber(
                values.cellphone.replace(/\D/g, "").slice(-11)
              );
            }

            const channel = localStorage.getItem("@tecimob/channel");

            if (channel) {
              values = {
                ...values,
                lead_origin: channel,
              };
            }

            const response = await signup({
              ...values,
              http_referrer: getReferrer(),
            });

            // eslint-disable-next-line
            window.enhanced_conversion_data = {
              email: values?.email,
              phone_number: removeDigits(values?.cellphone),
            };

            if (window?.gtag) {
              /*eslint no-unused-expressions: 0*/
              window?.gtag("event", "sign_up", {
                send_to: "G-0DT457F1ZX",
              });
            }

            // Marca que o formulário está carregando
            actions.setSubmitting(false);

            // Seta o access_token do painel no localStorage
            // para que mais tarde dentro da página de ativação
            // ele seja colocado em memória e assim removido posteriormente
            localStorage.setItem("token", response.data.data.access_token);

            navigate("/ativacao", {
              replace: true,
            });

            // Redireciona para pagina de ativação
            // window.location.href = "/ativacao";
          } catch (err) {
            console.log(err);
            const errors = err?.response?.data?.message;
            if (!!errors?.email) {
              actions.setErrors({
                email: `EMAIL_EXISTIS`,
              });
            }
          }
        }}
      >
        {({
          handleSubmit,
          errors,
          touched,
          isSubmitting,
          values,
          setErrors,
        }) => {
          const hasErrorAcceptTerms =
            errors.accept_terms && touched.accept_terms;

          if (errors.email === "EMAIL_EXISTIS") {
            return (
              <EmailExists
                email={values?.email}
                onClick={() => {
                  setErrors({});
                }}
              />
            );
          }

          return (
            <form className="js-form-signup" onSubmit={handleSubmit}>
              <FastField
                // eslint-disable-next-line
                autoFocus
                label="Nome"
                name="name"
                placeholder="Seu nome completo"
                className="js-input-name"
                component={InputBoxed}
              />
              <FastField
                type="tel"
                mask={PhoneMask(values.cellphone)}
                label="Telefone"
                name="cellphone"
                placeholder="Seu telefone"
                className="js-input-phone"
                component={InputBoxed}
              />
              <FastField
                type="email"
                label="E-mail"
                name="email"
                placeholder="Seu melhor e-mail"
                className="js-input-email"
                component={InputBoxed}
              />
              <Row>
                <ColPassword>
                  <FastField
                    type="password"
                    label="Senha"
                    name="password"
                    placeholder="Sua senha"
                    component={InputBoxed}
                  />
                </ColPassword>
                <ColPassword>
                  <FastField
                    type="password"
                    label="Confirme"
                    name="password_confirm"
                    placeholder="Confirme a senha"
                    component={InputBoxed}
                  />
                </ColPassword>
              </Row>
              <FastField
                name="work_option"
                component={Select}
                placeholder="Qual sua forma de trabalho?"
                options={workOptions}
              />
              <FastField
                name="team_size"
                component={Select}
                placeholder="Qual tamanho da sua equipe?"
                options={teamSizeOptions}
              />

              <FastField
                name="market_time"
                component={Select}
                placeholder="Qual seu tempo no mercado?"
                options={marketTimeOptions}
              />

              <BoxTerms
                id="js-accept-terms-label"
                hasError={hasErrorAcceptTerms}
              >
                <FastField
                  id="js-accept-terms-input"
                  type="checkbox"
                  name="accept_terms"
                  component="input"
                />
                Concordo com{" "}
                <a
                  rel="noreferrer noopener"
                  target="_blank"
                  href="https://tecimob.com.br/termos-de-uso"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Termos de uso
                </a>{" "}
                e{" "}
                <a
                  rel="noreferrer noopener"
                  target="_blank"
                  href="https://tecimob.com.br/politica-privacidade"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Privacidade
                </a>
              </BoxTerms>
              {hasErrorAcceptTerms && (
                <Error>Você deve aceitar os termos.</Error>
              )}
              <Btn
                id="js-button-submit-signup"
                type="submit"
                color="primary"
                kind="solid"
                disabled={isSubmitting}
              >
                Criar Meu Site
              </Btn>
            </form>
          );
        }}
      </Formik>
    </Wrapper>
  );
};

export default Form;
