export const getRecaptcha = async () => {
  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6LcerJsUAAAAAKw6v70xur2BM2Y5k3tG74wSoSk8", {
          action: "submit",
        })
        .then(function (token) {
          resolve(token);
        })
        .catch(() => {
          reject(null);
        });
    });
  });
};
