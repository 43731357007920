import React from "react";
import PropTypes from "prop-types";
// Styles
import { Wrapper } from "./styles";

function Button({
  id,
  as,
  to,
  href,
  target,
  rel,
  type,

  // Style Props
  className,
  kind,
  color,
  block,
  disabled,
  style,

  // Event Handlers
  onClick,

  children,
}) {
  return (
    <Wrapper
      id={id}
      as={as ? as : undefined}
      to={to ? to : undefined}
      type={type}
      href={href}
      className={className}
      kind={kind}
      color={color}
      block={block ? block : undefined}
      disabled={disabled}
      style={style}
      onClick={onClick}
      target={target}
      rel={rel}
    >
      {children}
    </Wrapper>
  );
}

Button.defaultProps = {
  to: null,
  href: null,
  as: "button",
  target: null,
  rel: null,
  block: false,
  color: "primary",
  disabled: false,
  kind: "gradient",
  children: null,
  onClick: null,
};

Button.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  block: PropTypes.bool,
  target: PropTypes.string,
  rel: PropTypes.string,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "success",
    "white",
    "whatsapp",
  ]),
  disabled: PropTypes.bool,
  kind: PropTypes.oneOf(["gradient", "solid", "border"]),
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default Button;
