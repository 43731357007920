import React from "react";
import { navigate } from "gatsby";
import { isMobile } from "is-mobile";
import * as PropTypes from "prop-types";
import Button from "../Button/index";
import { getUrlSignin } from "helpers/referrer";

function Signin({
  block = false,
  color = "secondary",
  kind = "border",
  children,
  className,
}) {
  const onClick = (e) => {
    e.preventDefault();

    if (isMobile()) {
      navigate("/download");
      return null;
    }

    window.open(getUrlSignin(), "_blank");
  };

  return (
    <Button
      id="js-button-signin"
      block={block}
      as="a"
      role="button"
      target="_blank"
      rel="noopener noreferrer"
      color={color}
      kind={kind}
      onClick={onClick}
      className={className}
    >
      {children}
    </Button>
  );
}

Signin.defaultProps = {
  children: "Entrar",
};

Signin.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default Signin;
