import React from "react";
import PropTypes from "prop-types";
// Components
import { Errors, Error } from "components/Form/styles";
// Styles
import { Wrapper } from "./styles";

function FormGroupBoxed({
  as,
  field,
  form,
  form: { touched, errors },
  children,
}) {
  const error = errors[field.name];
  const hasError = touched[field.name] && error;

  return (
    <>
      <Wrapper as={as} hasError={hasError}>
        {children}
      </Wrapper>
      {hasError && (
        <Errors>
          <Error>{error}</Error>
        </Errors>
      )}
    </>
  );
}

FormGroupBoxed.defaultProps = {
  form: null,
  field: null,
};

FormGroupBoxed.propTypes = {
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
  }),
  field: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default FormGroupBoxed;
