import React from "react";
// Components
import FormGroup from "components/Form/FormGroup";
// Styles
import { Label } from "../styles";
import { Control } from "./styles";

function Textarea({ label, type, field, form, ...props }) {
  return (
    <FormGroup form={form} field={field}>
      <Label>{label}</Label>
      <Control as="textarea" type={type} {...field} {...props} />
    </FormGroup>
  );
}

Textarea.propTypes = {};
Textarea.defaultProps = {};

export default Textarea;
