import styled from "styled-components";

export const Label = styled.span`
  width: 100%;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 500;
  margin-bottom: 10px;
`;

export const BaseFormControl = styled.input`
  font-size: 14px;
  color: #272727;
  background-color: #f4f8ff;
  border-radius: ${({ theme }) => theme.input.borderRadius};
  border: 1px solid transparent;
  padding-left: 15px;
  padding-right: 15px;

  ::placeholder {
    color: rgba(39, 39, 39, 0.5);
    opacity: 1;
  }
`;

export const Errors = styled.div`
  margin-top: -5px;
  margin-bottom: 10px;
`;

export const Error = styled.div`
  font-size: 12px;
  width: 100%;
  color: #d74444;

  & + & {
    margin-top: 5px;
  }
`;
