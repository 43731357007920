import styled from "styled-components";
//import { BaseFormControl } from "../styles";

export const Control = styled.input`
  border: 0 none;
  height: 34px;
  line-height: 34px;
  padding: 0 10px;
  border-radius: 4px;
`;
