import React from "react";
import PropTypes from "prop-types";
import Masked from "react-text-mask";
// Components
import FormGroupBoxed from "components/Form/FormGroupBoxed";
// Styles
import { Label } from "../styles";
import { Control } from "./styles";

function Input({ mask, label, type, field, form, guide = false, ...props }) {
  return (
    <FormGroupBoxed form={form} field={field}>
      <Label>{label}</Label>
      <Control
        as={!!mask ? Masked : "input"}
        type={type}
        mask={mask}
        guide={guide}
        {...field}
        {...props}
      />
    </FormGroupBoxed>
  );
}

Input.defaultProps = {
  label: null,
  type: "text",
  field: null,
  form: PropTypes.object
};

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object
};

export default Input;
