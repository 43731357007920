import styled, { css } from "styled-components";
import { BaseFormControl, Label } from "components/Form/styles";

const hasError = ({ hasError, theme: { colors } }) => {
  if (!hasError) return null;

  return css`
    border-color: ${colors.error};

    input {
      color: ${colors.error};
    }

    ${Label} {
      color: ${colors.error};
    }
    ${BaseFormControl} {
      border-color: ${colors.error};
    }
  `;
};

export const Wrapper = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  border: 1px solid #d3dceb;
  border-radius: 4px;
  background-color: #fff;

  ${Label} {
    font-weight: 700;
    margin-bottom: 0;
    padding: 10px 10px 0 10px;
  }
  ${hasError}
`;
