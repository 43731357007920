import React from "react";
import { Wrapper, Message, Link, SignInButton } from "./styles";
import Button from "components/Button";
import Signin from "components/Signin";

const EmailExists = ({ email, onClick }) => {
  return (
    <Wrapper>
      <Message>
        O e-mail <span>{email}</span> já está sendo utilizado em outra conta do
        Tecimob. Caso você realmente queira criar outra conta, utilize um e-mail
        diferente.
      </Message>

      <Button block color="tertiary" kind="solid" onClick={onClick}>
        Tentar com outro e-mail
      </Button>
      <SignInButton block color="tertiary">
        Acessar minha conta
      </SignInButton>
    </Wrapper>
  );
};

export default EmailExists;
