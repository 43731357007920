import styled from "styled-components";
import { BaseFormControl } from "../styles";

export const Control = styled(BaseFormControl).attrs(() => ({
  as: "Select"
}))`
  height: ${({ theme }) => theme.input.height};
  line-height: ${({ theme }) => theme.input.height};
  font-family: inherit;
  border: 1px solid #d3dceb;
  background: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 5" width="10" height="5"><polygon points="0,0 10,0 5,5" fill="black"/></svg>') no-repeat right 15px center;
  padding-right: 30px; /* Increase this value to add more space */
`;
