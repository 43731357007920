import styled, { css } from "styled-components";
import { BaseFormControl, Label } from "components/Form/styles";

const hasError = ({ hasError, theme: { colors } }) => {
  if (!hasError) return null;

  return css`
    ${Label} {
      color: ${colors.error};
    }
    ${BaseFormControl} {
      border-color: ${colors.error};
    }
  `;
};

export const Wrapper = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  ${hasError}
`;
