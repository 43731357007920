import styled, { css } from "styled-components";
import { rgba } from "polished";
import Button from "components/Button";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 340px;
  margin: 0 auto;

  header {
    text-align: center;
    margin-bottom: 15px;
  }
`;
export const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 600;
`;
export const Text = styled.p``;

export const Row = styled.div`
  display: flex;
  margin: 0 -7px;
`;
export const ColPassword = styled.div`
  width: 50%;
  padding: 0 7px;
`;

export const Btn = styled(Button).attrs(() => ({
  type: "submit",
}))`
  width: 100%;
  font-size: 14px;
  padding: 10px 20px;
  height: 40px;
  background-color: #0084f4;

  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`;

export const BoxTerms = styled.label`
  display: block;
  padding: 15px 15px;
  background-color: #e9eef5;
  border: 1px solid #d3dceb;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;

  ${(p) =>
    p.hasError &&
    css`
      border-color: ${(p) => rgba(p.theme.colors.error, 0.5)};
      background-color: ${(p) => rgba(p.theme.colors.error, 0.2)};
    `}

  a {
    color: #0084f4;
  }

  input {
    margin-right: 10px;
    vertical-align: middle;
    margin-top: -1px;
  }
`;

export const Error = styled.span`
  color: ${(p) => p.theme.colors.error};
  margin-top: -12px;
  display: block;
  margin-bottom: 15px;
`;
