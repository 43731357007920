import qs from "qs";
import LocalStorage from "./LocalStorage";
import { clearSearchParams } from "./url-helpers";

// Key principal aonde armazena o referrer
const KEY_REFERRER = "tecimob-referrer";
const KEY_CURRENT_REFERRER = "tecimob-current-referrer";

/**
 * Retorna o true caso tenha algo de utm
 * @param queryString
 */
export function hasUtm(queryString) {
  const regex = new RegExp(/(utm_source|utm_medium|utm_campaign)/);
  return !!queryString.match(regex);
}

/**
 * Retorna true se a url tem o parametro do google
 * @param queryString
 */
export function isGoogleAds(queryString) {
  return queryString.includes("gclid");
}

/**
 * Retorna a url do referrer
 * @return {string}
 */
export function getReferrer() {
  return LocalStorage.get(KEY_REFERRER) || "";
}

/**
 * Retorna a url do referrer atual
 * @return {string}
 */
export function getCurrentReferrer() {
  return LocalStorage.get(KEY_REFERRER) || "";
}

/**
 * Pega os 2 referrer
 * @returns {string}
 */
export function getAllReferrer() {
  try {
    const current = getCurrentReferrer().substring(1);
    const first = getReferrer().substring(1);

    const allReferrer = {
      current,
      first
    };

    return qs.stringify(allReferrer, { addQueryPrefix: true });
  } catch {
    return "";
  }
}

/**
 * Seta o referrer
 * @param value
 * @param KEY
 */
export function setReferrer(value, KEY = KEY_REFERRER) {
  let currentValue = value;

  if (typeof value === "object") {
    currentValue = qs.stringify(value, { encode: false, addQueryPrefix: true });
  }

  return LocalStorage.set(KEY, decodeURI(currentValue));
}

export function setCurrentReferrerSource(queryString, hasSearchParams) {
  // Verifica se é do google ou tem utm
  if (hasSearchParams) {
    setReferrer(queryString, KEY_CURRENT_REFERRER);
    return null;
  }

  // Verifica se tem referrer
  // se tiver seta o que ta vindo se não deixa
  // não seta nada se não tiver origem
  if (document.referrer)
    setReferrer({ referrer: document.referrer }, KEY_CURRENT_REFERRER);
}

/**
 * Seta o referer se for necessário
 * @param queryString
 * @return {null}
 */
export function setReferrerSource(queryString) {
  const refererStorage = getReferrer();
  const hasSearchParams = isGoogleAds(queryString) || hasUtm(queryString);

  // Limpa a url se tiver algum parametro
  // relacionado a referencia de onde veio o clique
  if (hasSearchParams) clearSearchParams();

  // Seta a referencia de onde veio o lead atual
  setCurrentReferrerSource(queryString, hasSearchParams);

  // Se não tem queryString já retorna nulo
  // se ja tiver algo no sourceStorage já retorna null
  // se tiver algo no refererStorage já retorna null também
  if (refererStorage) return null;

  // Verifica se é do google ou tem utm
  if (hasSearchParams) {
    setReferrer(queryString);
    return null;
  }

  // Verifica se tem referrer
  // se tiver seta o que ta vindo se não deixa
  // não seta nada se não tiver origem
  if (document.referrer) setReferrer({ referrer: document.referrer });
}

/**
 * Retorna a url de cadastro do tecimob
 * @param params
 * @return {string}
 */
export function getUrlSignup() {
  const BASE_APP = process.env.GATSBY_BASE_APP_URL;
  return `${BASE_APP}/signup${getAllReferrer()}`;
}

/**
 * Retorna a url de login do tecimob
 * @return {string}
 */
export function getUrlSignin() {
  const BASE_APP = process.env.GATSBY_BASE_APP_URL;
  return `${BASE_APP}/${getAllReferrer()}`;
}
