import styled, { css } from "styled-components";
import { theme } from "config-styles";
import { borderRadius } from "polished";

export const ButtonGroup = styled.div`
  display: flex;
`;

export const activeStyles = ({ isActive }) => {
  if (isActive) {
    return css`
      color: #ffffff;
      background-color: ${({ theme }) => theme.colors.primary};
      border-color: transparent;
    `;
  }
};

export const Button = styled.button`
  font-size: 14px;
  font-weight: 500;
  flex-grow: 1;
  border: 1px solid #dde5f2;
  background-color: transparent;
  height: ${theme.button.height};

  &:first-child {
    ${borderRadius("left", "4px")}
  }

  &:last-child {
    ${borderRadius("right", "4px")}
  }

  & + & {
    border-left: 0 none;
  }

  ${activeStyles}
`;
