import React from "react";
import PropTypes from "prop-types";
// Components
import FormGroup from "components/Form/FormGroup";
// Styles
import { Label } from "../styles";
import { Control } from "./styles";

function Input({ label, type, field, form, ...props }) {
  return (
    <FormGroup form={form} field={field}>
      <Label>{label}</Label>
      <Control type={type} {...field} {...props} />
    </FormGroup>
  );
}

Input.defaultProps = {
  label: null,
  type: "text",
  field: null,
  form: PropTypes.object
};

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object
};

export default Input;
