import React from "react";
import PropTypes from "prop-types";
// Components
import FormGroup from "components/Form/FormGroup";
// Styles
import { Label } from "../styles";
import { ButtonGroup as Wrapper, Button } from "./styles";

function ButtonGroup({ labelKey, valueKey, label, field, form, options }) {
  let renderedButtons = null;

  if (options) {
    renderedButtons = options.map((option, i) => {
      const value = option[valueKey] || option[labelKey];
      const isActive = field.value === value;

      return (
        <Button
          key={`button-${labelKey}-${valueKey}-${i}`}
          type="button"
          isActive={isActive}
          onClick={() => {
            form.setFieldValue(field.name, value);
          }}
          value={value}
        >
          {option[labelKey]}
        </Button>
      );
    });
  }

  return (
    <FormGroup as="div" form={form} field={field}>
      <Label>{label}</Label>
      <Wrapper>{renderedButtons}</Wrapper>
    </FormGroup>
  );
}

ButtonGroup.defaultProps = {
  label: null,
  field: null,
  form: PropTypes.object,
  labelKey: "label",
  valueKey: "value"
};

ButtonGroup.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string
};

export default ButtonGroup;
