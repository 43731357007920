import { mixed } from "yup";

export const PhoneMask = (value) => {
  const sanitizedValue = value.replace(/\D/g, "");

  if (sanitizedValue.length < 11) {
    return [
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }
  if (sanitizedValue.length >= 13) {
    return [
      "+",
      /\d/,
      /\d/,
      " ",
      "(",
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }

  return [
    "(",
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
};

export const PHONE_MASK = [
  "(",
  "+",
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

/**
 * Submit nativo do HTML em um objeto
 * @param action
 * @param method
 * @param values
 * @param nameExtractor
 */
export function submitForm({ action, method = "post", values, nameExtractor }) {
  const $form = document.createElement("form");
  $form.action = action;
  $form.method = method;

  Object.keys(values).forEach((name) => {
    let $input = document.createElement("input");
    $input.type = "hidden";
    $input.name = nameExtractor ? nameExtractor(name) : name;
    $input.value = values[name];
    $form.appendChild($input);
  });

  // Bota o formulario no body
  // Se não colocar não da de dar submit
  document.body.append($form);

  // da submit no formulário
  $form.submit();
}

export function equalTo(ref, msg) {
  return mixed().test({
    name: "equalTo",
    exclusive: false,
    message: msg || "${path} must be the same as ${reference}",
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref);
    },
  });
}
