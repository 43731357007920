export default class LocalStorage {
  static set(key, value) {
    if (typeof window === "undefined") return null;
    return localStorage.setItem(key, value);
  }

  static get(key) {
    if (typeof window === "undefined") return null;
    return localStorage.getItem(key);
  }
}
