import { api } from "services";
import { getRecaptcha } from "services/getRecaptcha";

/**
 * Envia um contato para o suporte
 * @return {Promise}
 */
export const signup = async (values) => {
  const recaptcha = await getRecaptcha();
  return api.post("signup", { ...values, recaptcha });
};
