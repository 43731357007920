import axios from "axios";

export const api = axios.create({
  baseURL: process.env.GATSBY_API_URL
});

/**
 * Faz o request com uma estratégia de offline-first
 * @param {string} url
 * @param {object} config
 * @return {Promise<AxiosResponse<T>|any>}
 */
export const request = async (url, config) => {
  try {
    // Pega a resposta se existir na session storage
    const responseSession = sessionStorage.getItem(url);

    // Se tiver dados na sessão retorna a resposta
    if (responseSession) {
      // Retorna a resposta completa
      return JSON.parse(responseSession);
    }

    // Faz o request
    const response = await api.request(url, config);

    // Armazena a resposta do request dentro da sessionStorage
    sessionStorage.setItem(url, JSON.stringify(response));

    // Retorna a resposta
    return response;
  } catch (err) {
    return err;
  }
};
