import styled, { css } from "styled-components";
import {
  buttonColorSolid,
  buttonColorGradient,
  buttonColorBorder,
} from "./mixins";
import { colors } from "config-styles";

const withBlock = ({ block }) => {
  if (!block) return null;

  return css`
    width: 100%;
  `;
};

const buttonColors = ({ kind, color: colorName }) => {
  const color = colors[colorName];

  switch (kind) {
    case "solid": {
      return buttonColorSolid(color);
    }
    case "gradient": {
      return buttonColorGradient(color);
    }
    case "border": {
      return buttonColorBorder(color);
    }
    default:
      console.warn("Cor invalida");
  }
};

const disabledStyle = ({ disabled }) => {
  if (disabled) {
    return css`
      pointer-events: none;
      opacity: 0.5;
    `;
  }
};

export const Wrapper = styled.button`
  display: inline-block;
  font-size: ${(props) => props.theme.button.fontSize};
  line-height: 14px;
  padding: 15px 20px;
  height: 45px;
  border: 0 solid transparent;
  vertical-align: middle;
  border-radius: ${(props) => props.theme.button.borderRadius};
  cursor: pointer;
  font-weight: 500;
  white-space: nowrap;
  text-decoration: none;
  font-family: inherit;

  ${(props) => {
    if (props.kind !== "border") return null;

    return css`
      padding: 13px 20px;
    `;
  }}

  svg {
    line-height: 1em;
    vertical-align: top;
  }

  ${withBlock}
  ${buttonColors}
  ${disabledStyle}
`;
