import qs from "qs";

/**
 * Pega os parametros da url e converte para um objeto
 * @return {object}
 */
export const getUrlParams = () => {
  try {
    return qs.parse(window.location.search, { ignoreQueryPrefix: true });
  } catch {
    return {};
  }
};

/**
 * Limpa os parametros da url
 */
export const clearSearchParams = () => {
  if (typeof window !== "undefined") {
    window.history.replaceState(
      {},
      document.title,
      window.location.origin + window.location.pathname
    );
  }
};
