import React from "react";
import PropTypes from "prop-types";
// Components
import { Error } from "components/Form/styles";
// Styles
import { Wrapper } from "./styles";

function FormGroup({ as, field, form, form: { touched, errors }, children }) {
  const error = errors[field.name];
  const hasError = touched[field.name] && error;

  return (
    <Wrapper as={as} hasError={hasError}>
      {children}
      {hasError && <Error>{error}</Error>}
    </Wrapper>
  );
}

FormGroup.defaultProps = {
  form: null,
  field: null
};

FormGroup.propTypes = {
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object
  }),
  field: PropTypes.shape({
    name: PropTypes.string
  })
};

export default FormGroup;
