import { css } from "styled-components";
import { readableColor, darken, lighten } from "polished";
import { colors } from "config-styles";

export const buttonColorSolid = color => css`
  color: ${readableColor(darken(0.4, color), colors.secondary, "#fff")};
  background: ${color};
`;

export const buttonColorGradient = color => css`
  color: ${readableColor(darken(0.4, color), colors.secondary, "#fff")};
  background: linear-gradient(to right, ${color}, ${lighten("0.15", color)});
`;
export const buttonColorBorder = color => css`
  color: ${darken(0.13, color)};
  border: 2px solid ${color};
  background: transparent;
`;
