import styled from "styled-components";
import { BaseFormControl } from "../styles";

export const Control = styled(BaseFormControl)`
  min-height: ${({ theme }) => theme.textarea.height};
  padding-top: 15px;
  padding-bottom: 15px;
  resize: none;
  font-family: inherit;
`;
