// Initialize reCAPTCHA
export const loadRecaptcha = () => {
  if (
    typeof window !== "undefined" &&
    typeof window.grecaptcha === "undefined"
  ) {
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?render=6LcerJsUAAAAAKw6v70xur2BM2Y5k3tG74wSoSk8";
    document.body.appendChild(script);
  }
};
