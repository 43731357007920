import React from "react";
import PropTypes from "prop-types";
import Masked from "react-text-mask";
// Components
import FormGroup from "components/Form/FormGroup";
// Styles
import { Label } from "../styles";
import { Control } from "../Input/styles";

function InputMasked({ label, type, field, form, ...props }) {
  return (
    <FormGroup form={form} field={field}>
      <Label>{label}</Label>
      <Control as={Masked} type={type} {...field} {...props} />
    </FormGroup>
  );
}

InputMasked.defaultProps = {
  label: null,
  type: "text",
  guide: false
};

InputMasked.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  guide: PropTypes.bool
};

export default InputMasked;
